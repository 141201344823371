export function lookupRelationsByEids(eids) {
  return eids.map((eid) => findRelationByEid(eid)).filter((el) => el)
}

export function findRelationByEid(eid) {
  const relation = document.querySelector(`[data-eid="${eid}"]`)
  if (!relation) { return undefined; }

  return {
    eid,
    abbr: relation.querySelector('[data-field-name="abbr"]')?.value,
    independentVariable: relation.querySelector('[data-field-name="independent_variable"]')?.value,
    dependentVariable: relation.querySelector('[data-field-name="dependent_variable"]')?.value,
    supported: relation.querySelector('[data-field-name="supported"]')?.value,
    tab: findTab(relation)
  }
}

export function findTab(element) {
  const tabContent = element.closest('.tab-content')
  const tabLink = document.querySelector(`[data-tab="${tabContent.id}"]`)

  return { name: tabLink.innerText, id: tabContent.id }
}
