import { Controller } from '@hotwired/stimulus'
import { lookupRelationsByEids } from 'helpers/admin_paired_relations'

export default class extends Controller {
  static targets = ['results']
  static values = { eids: Array, inputName: String }

  connect() {
    this._renderPairedRelations()
  }

  remove( { params } ) {
    if (!params.eid) { return }

    this.eidsValue = this.eidsValue.filter((eid) => eid != params.eid)
  }

  update({ target }) {
    if (!this.eidsValue.includes(target.value)) {
      this.eidsValue = this.eidsValue.concat([target.value])
    }
    target.value = ''

    this._renderPairedRelations()
  }

  _renderPairedRelations() {
    if (!this.hasResultsTarget) { return }

    const results = lookupRelationsByEids(this.eidsValue)

    this.resultsTarget.innerHTML = results.map((result) => this._renderPairedRelation(result)).join('')
  }

  _renderPairedRelation(result) {
    const id = Math.random()
    return `<div class="result-box nested-form-wrapper" data-new-record="true">
              <div class="grouped-fields">
                <div class="field xs">
                  <label for="tab-${id}">
                    <a class="var" data-action="click->tabs#switchTo"
                                   data-tabs-tab-id-param="${result.tab.id}"
                                   href="javascript:void(0)">
                      Tab
                    </a>
                  </label>
                  <input disabled="disabled" id="tab-${id}" type="text" value="${result.tab.name}">
                </div>
                <div class="field xs">
                  <label for="abbr-${id}">Abbr</label>
                  <input disabled="disabled" id="abbr-${id}" type="text" value="${result.abbr}">
                </div>
                <div class="field">
                  <label for="independent-${id}">Independent</label>
                  <input disabled="disabled" id="independent-${id}" type="text" value="${result.independentVariable}">
                </div>
                <div class="field">
                  <label for="dependent-${id}">Dependent</label>
                  <input disabled="disabled" id="dependent-${id}" type="text" value="${result.dependentVariable}">
                </div>
                <div class="field xs">
                  <label for="supported-${id}">Supported?</label>
                  <input disabled="disabled" id="supported-${id}" type="text" value="${result.supported}">
                </div>
                <input name="${this.inputNameValue}" id="eid-${id}" type="hidden" value="${result.eid}">
              </div>
              <a class="remove-button danger"
                data-action="click->admin--panel-form#removeItem admin--paired-relations#remove nested-form#remove"
                data-admin--panel-form-target="removeButton"
                data-admin--paired-relations-eid-param="${result.eid}"
                href="javascript:void(0);">
                <i class="fas fa-minus-circle" aria-hidden="true"></i>
              </a>
            </div>`
  }
}
